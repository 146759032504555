<template>
    <div class="column d-flex mt-5 pt-5 align-items-center justify-content-center flex-column">
        <h1 class="font-primary success-heading mt-5 pt-5">Success!</h1>
        <img class="success-icon" :src="checkmarkIcon" alt="">
        <p>Thank you for verifying your email address, you can now <router-link style="text-decoration:underline" to='/login'>login</router-link>.</p>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import checkmarkIcon from '../../assets/images/check-blue.svg'

export default defineComponent({
    name: 'Success',
    data: function() {
        return {
            checkmarkIcon: checkmarkIcon
        }
    }
})
</script>
